import ComponentRepository from './architecture/componentRepository';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        'header': () => import('./components/top-navigation'),
        'mobile-navigation': () => import('./components/mobile-navigation'),
        'site-search': () => import('./components/site-search'),
        'project-page': () => import('./components/project-page'),
        'project-swiper': () => import('./components/project-swiper'),
        'hero-swiper': () => import('./components/hero-swiper'),
        'hero-video': () => import('./components/hero-video'),
        'short-content': () => import('./components/short-content'),
        'short-content-swiper': () => import('./components/short-content-swiper'),
        'image-gallery': () => import('./components/image-gallery'),
        'accordion': () => import('./components/accordion'),
        'top-navigation': () => import('./components/top-navigation'),
        'media-swiper': () => import('./components/media-swiper'),
        'nav-swiper': () => import('./components/nav-swiper'),
        'slider-section': () => import('./components/slider-section'),
        'swiper': () => import('../../node_modules/swiper/swiper-bundle.js'),
        'modal': () => import('./components/modal'),
        'map': () => import('./components/map'),
        'slider-swiper': () => import('./components/slider-swiper'),
        'data-baseline': () => import('./components/data-baseline'),
        'nice-select': () => import('./components/nice-select'),
        'newsletter-signup': () => import('./components/newsletter-signup'),
        'global-tools': () => import('./components/global-tools'),
        'form-effects': () => import('./components/form-effects'),
        'news-overview': () => import('./components/news-overview'),
        'project-overview': () => import('./components/project-overview'),
        'document-swiper': () => import('./components/document-swiper'),
        'news-page-hero': () => import('./components/news-page-hero'),
        'document-macro': () => import('./components/document-macro'),
        /*
        This is an example component that demonstrates how to implement a filterable map. It should not be used in production sites as is but can serve as inspiration.
        'domain-filterable-map': () => import('./components/example-components/example-domain-filterable-map'),
        */
    });
});
